<template>
  <div v-if="formData" class="details--body__edit">
    <div class="mb-1 form-control">
      <div>
        <strong>Adresse chantier :</strong>
      </div>
      <div>
        <input class="reset-input" type="text" v-model="formData.address" />
      </div>
    </div>
    <div class="form-control">
      <div>
        <strong>Ville :</strong>
      </div>
      <div>
        <input class="reset-input" type="text" v-model="formData.city" />
      </div>
    </div>
    <div class="form-control">
      <div>
        <strong>Code postal :</strong>
      </div>
      <div>
        <input class="reset-input" type="text" v-model="formData.postal_code" />
      </div>
    </div>
    <div class="form-control">
      <div>
        <strong>Téléphone :</strong>
      </div>
      <div>
        <input class="reset-input" type="text" v-model="phoneField" />
      </div>
    </div>
    <p class="mb-0 mt-1">
      <strong>E-mail : </strong>
      <a :href="`mailto:${userContact.email}`">{{
        userContact.email
      }}</a>
    </p>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
  name: "ContactDetailsEdit",
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: null,
      phoneField: null,
    };
  },
  created() {
    this.formData = Object.assign({}, {
      ...this.projectAddress
    });
    this.phoneField = this.userContact?.phone
  },
  computed: {
    ...mapGetters({
      projectAddress: "project/getCurrentProjectAddress",
      userContact: "project/getCurrentUserAddress"
    })
  },
  watch: {
    formData: {
      deep: true,
      handler(newVal) {
        this.$emit("updateModel", newVal);
        this.$emit("updatePhone", this.phoneField);
      },
    },
    phoneField: {
      handler(newVal) {
        this.$emit("updatePhone", newVal);
      },
    },
  },
};
</script>

<style></style>
